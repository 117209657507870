import React from 'react'
import './Footer.css'

export default class Footer extends React.Component {
  render() {
  return (
    <footer>
      	&copy; <a href='https://www.linkedin.com/in/ryuta-eguchi-47371982' target='_blank' rel="noopener noreferrer">Ryuta Eguchi 2019</a> 
    </footer>
  )
}
}
